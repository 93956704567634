//INFO: 지재권 게시판 // TODO: 연구실 관리 계정으로 페이지에 들어오면 해당 연구실의 Data만 보여주기
<script>
import { getLabPatent } from '@/api/platform/community'
import { GridComponent, ColumnsDirective, Page, Sort, Toolbar, Search, CommandColumn } from '@syncfusion/ej2-vue-grids'
import { patentDelete } from '@/api/admin/ntis/ntisPatentAPI'
import AddBtn from '@/components/ui/AddBtn.vue' // 지재권 등록

import LoadingVue from '@/components/ui/Loading.vue'
// INFO: 검색 기능
import Dropdown from '@/components/ui/Dropdown.vue'

export default {
  name: 'adminBoardPatentList',
  components: {
    'ejs-grid': GridComponent,
    'e-columns': ColumnsDirective,
    AddBtn,
    LoadingVue,
    // INFO: 검색 기능
    Dropdown
  },
  data() {
    const labNo = Number(this.$route.params.labNo)

    return {
      currentPath: this.$route.matched[0].path, // 현재 접속 경로
      currentPage: '', // 현재 페이지 번호
      patentGrid: '', // 지재권 그리드
      labNo, // 연구실 번호

      // ********** 그리드 세팅 **********
      pageSettings: { pageSize: 10 }, // 10개씩 잘라서 페이지네이션
      toolbar: ['Search'], // 툴바에 검색 옵션
      commands: [{ buttonOption: { content: '삭제', cssClass: 'e-danger' } }],
      formatoptions: { type: 'dateTime', format: 'y/M/d' }, // 컬럼 날짜 포맷. 시간까지 보이게 하려면 format: 'M/d/y hh:mm a'

      // ********** 지재권 데이터 **********
      patentData: [],
      // INFO: 검색 기능
      persistedData: [],
      searchConditions: [
        { value: 'all', text: '전체' },
        { value: 'labNo', text: '연구실번호' },
        { value: 'labName', text: '연구실이름' },
        { value: 'patentResultTitle', text: '출원등록명 ' },
        { value: 'patentRegistCountry', text: '출원등록국' },
        { value: 'patentRegistNumber', text: '출원등록번호' },
        { value: 'patentRegistrant', text: '출원등록인' }
      ],
      searchBy: ''
    }
  },
  provide: {
    grid: [Page, Sort, Toolbar, Search, CommandColumn]
  },

  watch: {
    async $route() {
      await this.loadPatentData()
    }
  },

  methods: {
    // ********** 지재권 데이터 가져오기 **********
    async loadPatentData() {
      const user = this.$store.state.userStore.info

      const data = user.groupName === 'admin' ? await getLabPatent() : await getLabPatent({ labNo: user.labNo })

      // 연구소 번호가 0이 아닐 때 연구소 번호에 맞는 지재권만 보여주기
      const data2 = this.labNo !== 0 ? await getLabPatent({ labNo: this.labNo }) : ''

      if (this.labNo !== 0) {
        this.patentData = data2.items
      } else {
        this.patentData = data.items
      }
    },

    // ********** 등록 버튼 **********
    goPatentCreate() {
      this.$router.push(`${this.currentPath}/w`)
    },

    // ********** 한 행 클릭 시 보기로 이동 **********
    patentRowSelected(e) {
      // 그리드 현재 페이지
      this.currentPage = this.patentGrid.pagerModule.pageSettings.currentPage

      if (e.target.matches('button')) return

      // 연구소 번호가 0이 아닐 때 연구소 번호 보내주기
      if (this.labNo !== 0) {
        this.$router.push(`/admin/board/patent/w/${e.data.patentId}/${this.currentPage}/${e.data.labNo}`)
      } else {
        this.$router.push(`/admin/board/patent/w/${e.data.patentId}/${this.currentPage}/0`)
      }
    },

    // ********** 삭제 버튼 **********
    commandClick(e) {
      const target = e.target
      if (target.matches('button.e-danger')) return this.delContent(e)
    },

    // ********** 삭제 동작 **********
    async delContent(e) {
      if (!confirm('정말로 삭제하시겠습니까?')) return

      const id = e.rowData.patentId

      const result = await patentDelete(id)

      if (result.code) return alert(result.message)

      alert('삭제되었습니다.')
      this.patentData = this.patentData.filter((item) => item.patentId !== id)
    },

    // INFO: 검색 기능
    actionComplete(e) {
      if (e.requestType === 'searching') return this.searchInSyncfusionGrid(e)
    },
    searchInSyncfusionGrid(e) {
      if (this.searchBy === 'all') return
      this.patentData = this.persistedData.filter((item) => item[this.searchBy]?.toString().includes(e.searchString) ?? true)
    },
    selectDropdownHandler(opt) {
      this.searchBy = opt.value
    },
    setSearchOptions() {
      const searchWrapper = this.$refs.grid.$el.querySelector('.e-toolbar-right')
      const dropdown = this.$refs.selectOption.$el
      searchWrapper.prepend(dropdown)

      searchWrapper.style.display = 'flex'
      searchWrapper.style.gap = '1em'
      dropdown.querySelector('button').style.border = 'none'
    },
    checkSearchState() {
      const { labNo, board, page } = this.$store.state.searchStateStore

      if (board !== 'patent') {
        this.$store.dispatch('searchStateStore/initailize')
        return false
      }

      if (this.labNo) return false

      if (!labNo) return false

      this.labNo = labNo
      this.$router.replace(`/admin/board/patent/${labNo}`)
    }
  },

  async mounted() {
    this.isLoading = true

    // 지재권 그리드
    this.patentGrid = this.$refs.grid.ej2Instances

    // 파라미터 지재권 그리드 페이지 번호
    const page = Number(this.$route.params.page)

    this.checkSearchState()

    // 지재권 전체 데이터 가져오기
    await this.loadPatentData()

    // 페이지 번호가 있을 때(지재권 보기 -> 목록으로 돌아온 경우) 받아온 페이지 번호로 SET
    if (page) {
      this.patentGrid.pagerModule.pageSettings.currentPage = page
    } else {
      this.patentGrid.pagerModule.pageSettings.currentPage = 1
    }

    // INFO: 검색 기능
    this.setSearchOptions()
    const getList = async () => {
      this.patentData = this.patentData.map((item) => {
        return {
          patentId: item.patentId,
          labNo: item.labNo,
          labName: item.labName,
          patentResultTitle: item.patentResultTitle,
          patentRegistCountry: item.patentRegistCountry,
          patentRegistNumber: item.patentRegistNumber,
          patentRegistrant: item.patentRegistrant,
          createdAt: item.createdAt
        }
      })

      // INFO: 검색 기능
      this.persistedData = this.patentData
    }
    getList()
    this.isLoading = false
  }
}
</script>

<template scoped>
  <div class="">
    <!-- 등록 버튼 -->
    <AddBtn @click="goPatentCreate()"></AddBtn>

    <!-- 검색기능 -->
    <Dropdown
      class="select-wrapper"
      :options="searchConditions"
      :disabled="false"
      name="zipcode"
      ref="selectOption"
      @selected="selectDropdownHandler"
    ></Dropdown>

    <!-- 지재권게시판 그리드 -->
    <div class="">
      <ejs-grid
        ref="grid"
        :dataSource="patentData"
        :allowPaging="true"
        :pageSettings="pageSettings"
        :allowFiltering="true"
        :allowSorting="true"
        :toolbar="toolbar"
        :rowSelected="patentRowSelected"
        :commandClick="commandClick"
        :actionComplete="actionComplete"
      >
        <e-columns>
          <e-column field="patentId" headerText="번호" width="80" textAlign="center"></e-column>
          <e-column field="labNo" headerText="연구실번호" width="120" textAlign="center"></e-column>
          <e-column field="labName" headerText="연구실이름" width="200" textAlign="center"></e-column>
          <e-column field="patentResultTitle" headerText="출원등록명" width="500" textAlign="center"></e-column>
          <e-column field="patentRegistCountry" headerText="출원등록국" width="120" textAlign="center"></e-column>
          <e-column field="patentRegistNumber" headerText="출원등록번호" width="150" textAlign="center"></e-column>
          <e-column field="patentRegistrant" headerText="출원등록인" width="180" textAlign="center"></e-column>
          <e-column field="manage" headerText="관리" textAlign="center" :commands="commands" />
        </e-columns>
        <!-- <e-column field="createdAt" headerText="작성일" width="120" :format="formatoptions" type="date" textAlign="center"></e-column> -->
      </ejs-grid>
    </div>
  </div>
</template>

<style scoped>
.btnArea {
  height: 50px;
}
.btnArea > button {
  float: right;
}
</style>
